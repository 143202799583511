<template>
  <div class="flex flex-col gap-4">
    <section id="header" class="flex justify-center">
        <span class="font-bold text-2xl">
          Detalle del Proceso
        </span>
    </section>

    <section id="body" class="flex flex-col items-center gap-4">
      <div class="flex flex-col w-full lg:w-2/4">
        <span class="text-xs">Numero de Radicado:</span>
        <InputMask mask="99999-99-99-999-9999-99999-99" v-model="model.numeroRadicado" placeholder="99999-99-99-999-9999-99999-99" />
        <MessageError :text="errors.numeroRadicado" />
      </div>

      <div class="flex flex-col lg:flex-row w-full lg:w-2/4 gap-4">
        <div class="flex flex-col w-full lg:w-2/4 gap-4">
          <div class="flex flex-col w-full">
            <span class="text-xs">Fecha de Reparto:</span>
            <input type="datetime-local" v-model="model.fechaReparto" class="w-full p-inputtext" />
            <MessageError :text="errors.fechaReparto" />
          </div>
          <div class="flex flex-col w-full">
            <span class="text-xs">Fecha de Notificacion:</span>
            <input type="datetime-local" v-model="model.fechaNotificacion" class="w-full p-inputtext" />
            <MessageError :text="errors.fechaNotificacion" />
          </div>
          <div class="flex justify-between w-full">
            <section class="flex flex-col">
              <span class="text-xs">Tiene medida cautelar:</span>
              <div class="flex items-center gap-4">
                <span>No</span>
                <InputSwitch v-model="model.medidaCautelar" />
                <span>Si</span>
              </div>
              <MessageError :text="errors.medidaCautelar" />
            </section>
            <section class="flex flex-col">
              <span class="text-xs">Abogado externo:</span>
              <div class="flex items-center gap-4">
                <span>No</span>
                <InputSwitch v-model="model.abogadoExterno" />
                <span>Si</span>
              </div>
              <MessageError :text="errors.abogadoExterno" />
            </section>
          </div>
          <div class="flex flex-col w-full">
            <span class="text-xs">Responsable:</span>
            <Dropdown class="w-full"
                      :options="listUsuarios"
                      option-label="fullName"
                      option-value="id"
                      placeholder="Seleccione responsable"
                      v-model="model.responsableId"
                      :filter="true"
            />
            <MessageError :text="errors.responsableId" />
          </div>
          <div class="flex flex-col w-full">
            <span class="text-xs">Clase Proceso:</span>
            <Dropdown class="w-full"
                      :options="listClases"
                      option-label="nombre"
                      option-value="id"
                      placeholder="Seleccione responsable"
                      v-model="model.claseProcesoId"
            >
              <template #option="{ option }">
                <span>{{ option.tipoAssociated.nombre }} - {{ option.nombre }}</span>
              </template>
            </Dropdown>
            <MessageError :text="errors.claseProcesoId" />
          </div>
        </div>

        <div class="flex flex-col w-full lg:w-2/4 gap-4">
          <div class="flex flex-col w-full">
            <span class="text-xs">Observaciones:</span>
            <Textarea v-model="model.observaciones" :autoResize="true" rows="5" cols="30" />
            <MessageError :text="errors.observaciones" />
          </div>
          <div class="flex flex-col w-full">
            <span class="text-xs">Descripcion Medida Cautelar:</span>
            <Textarea v-model="model.descripcionMedidaCautelar" :autoResize="true" rows="5" cols="30" />
            <MessageError :text="errors.descripcionMedidaCautelar" />
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full lg:w-2/4">
        <template v-if="adjuntos.length > 0">
          <div class="flex justify-between">
            <section>
              <i class="pi pi-file-pdf mr-2 text-black"></i>
              <span v-for="(item, key) in adjuntos" :key="key">{{ item.fileName }}</span>
            </section>
            <div class="">
              <button @click="quitarPdf(key)"><i class="pi pi-trash mr-2 text-red-600"></i></button>
            </div>
          </div>
        </template>
        <button label="Show" icon="pi pi-external-link" @click="openMaximizable" class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
          Agregar nuevo soporte
        </button>
      </div>
    </section>

    <section id="footerInfo" class="flex justify-center gap-4">
      <div class="flex justify-between w-full lg:w-2/4">
        <Button label="Atras"
                icon="pi pi-angle-left"
                @click="backPage()"
        />
        <Button label="Guardar"
                icon="pi pi-angle-right"
                iconPos="right"
                @click="save()"
        />
      </div>
    </section>
  </div>
  <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div>
        <label class="text-sm" for="titulo">Titulo</label>
        <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
               class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="my-2">
        <label class="text-sm">Adjunte el documento</label>
        <div class="mt-2">
          <FilePond ref="pond"/>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
    </template>
  </Dialog>
</template>

<script>
import { computed, ref, onActivated } from 'vue'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import FilePond from '../../../../uploadFilePoundNew/index.vue'
import FilePondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'
import StoreUsuarios from '@/apps/pharmasan/juridica/procesosJuridicos/store/usuarios.store'
import ProcesosJudicialesStore from '../../../../../store/procesosJudiciales.store'
import { useRouter } from 'vue-router'

export default {
  name: 'DetalleProceso',
  components: {
    FilePond
  },
  setup () {
    const router = useRouter()
    const adjuntos = ref([])
    const displayMaximizable = ref(false)
    const pond = ref()
    const title = ref('')

    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )
    const listUsuarios = computed(() => StoreUsuarios.getters.usuarios)
    const listClases = computed(() => ProcesosJudicialesStore.getters.getListClasesProcesosJudiciales)
    const newProcesoJudicial = computed(() => ProcesosJudicialesStore.getters.getNewProcesoJudicial)

    const schema = yup.object({
      numeroRadicado: yup.string().nullable().required('El campo es requerido').label(''),
      fechaReparto: yup.string().nullable().required('El campo es requerido').label(''),
      fechaNotificacion: yup.string().nullable().required('El campo es requerido').label(''),
      medidaCautelar: yup.bool().required('El campo es requerido'),
      abogadoExterno: yup.bool().required('El campo es requerido'),
      descripcionMedidaCautelar: yup.string().required('El campo es requerido'),
      observaciones: yup.string().required('El campo es requerido'),
      responsableId: yup.string().required('El campo es requerido'),
      claseProcesoId: yup.string().required('El campo es requerido')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('numeroRadicado')
    useField('fechaReparto')
    useField('fechaNotificacion')
    useField('medidaCautelar', null, { initialValue: false })
    useField('abogadoExterno', null, { initialValue: false })
    useField('descripcionMedidaCautelar')
    useField('observaciones')
    useField('responsableId')
    useField('claseProcesoId')

    const quitarPdf = (key) => {
      adjuntos.value.splice(key, 1)
    }

    const openMaximizable = () => {
      displayMaximizable.value = true
    }

    const backPage = () => {
      router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.crear.informacion-juzgado' })
    }

    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }

    const save = handleSubmit((values) => {
      ProcesosJudicialesStore.dispatch('setNewProcesoJudicial', values).then(() => {
        newProcesoJudicial.value.paths = adjuntos.value
        ProcesosJudicialesStore.dispatch('guardarProcesoJudicial', newProcesoJudicial.value).then((response) => {
          router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.listado' })
        })
      })
    })

    onActivated(() => {
      StoreUsuarios.dispatch('getAll')
      ProcesosJudicialesStore.dispatch('actionListClasesProcesosJudiciales')
    })

    return {
      errors,
      model,
      save,
      adjuntos,
      displayMaximizable,
      pond,
      title,
      listUsuarios,
      backPage,
      quitarPdf,
      openMaximizable,
      closeMaximizable,
      listClases
    }
  }
}
</script>

<style scoped>

</style>
